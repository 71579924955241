import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { getCurrentLangKey } from "ptz-i18n"
import { langs, defaultLangKey } from "../../data/languages"

export const Link = function(props) {
  const langKey = getCurrentLangKey(
    langs,
    defaultLangKey,
    props.location.pathname
  )
  const link = `/${langKey}${props.to}`.replace(`/${defaultLangKey}/`, "/")
  return (
    <GatsbyLink to={link} className={props.className}>
      {props.children}
    </GatsbyLink>
  )
}

export default Link
