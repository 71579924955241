import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = props => (
  <StaticQuery
    query={graphql`
      {
        allImageSharp {
          nodes {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
      }
    `}
    render={data => {
      let imageNameString = props.filename

      const indexOfLine = imageNameString.indexOf("-") + 1
      const slicedImageNameString = imageNameString.slice(indexOfLine, -4)

      const image = data.allImageSharp.nodes.find(n => {
        return n.fluid.srcSet.includes(slicedImageNameString)
      })
      if (!image || indexOfLine == null) {
        return null
      }

      return (
        <Img
          alt={props.alt}
          fluid={image.fluid}
          imgStyle={{
            width: `${props.width}px !important` || null,
            heigth: props.heigth || null,
          }}
        />
      )
    }}
  />
)

export default Image
