import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import style from "./style.module.scss"
import poster from "./poster.png"

export default function({ x, y, text, description, image, big }) {
  return (
    <header
      className={`${image ? style.container : ""} ${
        big && image ? style.big : ""
      }`}
    >
      {image ? (
        <Fragment>
          <Helmet>
            <title>{text}</title>
            <meta name="description" content={description} />
            <meta name="og:image" content={image} />
          </Helmet>
          {image.split(".").pop() === "mp4" && big ? (
            <Fragment>
              <video autoPlay loop muted poster={poster}>
                <source src={image} type="video/mp4" />
              </video>
              <img
                className={style.imgMobile}
                src={poster}
                alt={text}
                draggable="false"
              />
            </Fragment>
          ) : (
            <img src={image} alt={text} draggable="false" />
          )}
          <div
            className={style.line}
            style={{
              top: "10%",
              height: `${y - 10}%`,
              left: `${x}%`,
              width: `${90 - x}%`,
            }}
          />
          <div
            className={style.point}
            style={{ top: `${y}%`, left: `${x}%` }}
          />
          <div
            className={style.text}
            style={{
              top: "10%",
              right: "15%",
            }}
          >
            <h1>{text}</h1>
            <p>{description}</p>
          </div>
        </Fragment>
      ) : (
        <Helmet>
          <title>{text}</title>
          <meta name="description" content={description} />
        </Helmet>
      )}
    </header>
  )
}
