import React from "react"
import Link from "../Layout/Link"

import logo from "./logo.svg"
import logoSmall from "./logo_small.svg"
import { FormattedMessage } from "react-intl"

import { Navbar, Nav, Container } from "react-bootstrap"
import styles from "./style.module.scss"
import SelectLanguage from "./SelectLanguage"

export default function({ langs, location }) {
  return (
    <React.Fragment>
      <Container>
        <SelectLanguage langs={langs} />
      </Container>
      <Navbar
        sticky="top"
        variant="light"
        expand="lg"
        className={styles.navbar}
      >
        <Container>
          <Link location={location} to="/">
            <Navbar.Brand as="span">
              <img
                src={logo}
                alt="logo"
                width="200"
                className="d-block  d-lg-block"
              />
              <img
                src={logoSmall}
                alt="logo"
                width="200"
                className="d-none d-lg-none"
              />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className={styles.navbarToggler}
          />
          <Navbar.Collapse id="basic-navbar-nav" className="text-center">
            <Nav className="mr-auto" />
            <Nav className={`${styles.links} ml-auto`}>
              <Link
                location={location}
                to="/products/"
                activeClassName="active"
              >
                <Nav.Link as="span">
                  <FormattedMessage id="navigationProducts" />
                </Nav.Link>
              </Link>
              <Link
                location={location}
                to="/technology/"
                activeClassName="active"
              >
                <Nav.Link as="span" eventKey="technology">
                  <FormattedMessage id="navigationTechnology" />
                </Nav.Link>
              </Link>
              <Link
                location={location}
                to="/use-cases/"
                activeClassName="active"
              >
                <Nav.Link as="span">
                  <FormattedMessage id="navigationUsecases" />
                </Nav.Link>
              </Link>
              <Link
                location={location}
                to="/consulting/"
                activeClassName="active"
              >
                <Nav.Link as="span">
                  <FormattedMessage id="navigationConsulting" />
                </Nav.Link>
              </Link>
              <Link
                location={location}
                to="/partnership/"
                activeClassName="active"
              >
                <Nav.Link as="span">
                  <FormattedMessage id="navigationPartner" />
                </Nav.Link>
              </Link>
              <Link location={location} to="/career/" activeClassName="active">
                <Nav.Link as="span">
                  <FormattedMessage id="navigationCareer" />
                </Nav.Link>
              </Link>
              <Nav.Link
                as="a"
                className="btn btn-primary"
                style={{ color: "white" }}
                href="https://app.moonvision.io/signup"
              >
                <FormattedMessage id="navigationSignup" />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  )
}
