import React from "react"
import CookieConsent from "react-cookie-consent"
import { FormattedHTMLMessage, FormattedMessage } from "react-intl"
import style from "./style.module.scss"

export default function() {
  return (
    <CookieConsent
      location="bottom"
      buttonStyle={{
        color: "white",
        background: "#14a7d0",
        width: "95%",
        margin: "0 auto",
        marginBottom: "1vh",
      }}
      // buttonClasses={style.cookieButton}
      containerClasses={style.cookieContainer}
      contentClasses={style.cookieContent}
      buttonText={<FormattedMessage id="cookieAccept" />}
    >
      <FormattedHTMLMessage id="cookieText" />
    </CookieConsent>
  )
}
