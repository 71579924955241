import React from "react"
import { Link } from "gatsby"
import style from "./style.module.scss"
import { FormattedMessage } from "react-intl"

export default function({ langs }) {
  if (!langs) return

  const links = langs.map((lang, i) => (
    <Link
      to={lang.link}
      key={lang.langKey}
      style={{
        textDecoration: "none",
      }}
    >
      {lang.langKey}
      {i < langs.length - 1 && <span>/</span>}
    </Link>
  ))

  return (
    <div className={style.language}>
      <span className="d-none d-md-inline-block">
        <button
          onClick={() => {
            window.$crisp && window.$crisp.push(["do", "chat:open"])
          }}
        >
          <FormattedMessage id="contactUs" />
        </button>
      </span>{" "}
      <span>
        <FormattedMessage id="callUs" />:
        <a href="tel:0043 1 890 12 87"> +43 676 6918980</a>
      </span>{" "}
      <span>{links}</span>
    </div>
  )
}
