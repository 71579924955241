import React from "react"
import Link from "../Layout/Link"
import { Nav, Container, Col } from "react-bootstrap"
import { FormattedMessage } from "react-intl"

import forbes from "./forbesSVG.svg"
import isoCertification from "./isoCertification.png"

import styles from "./style.module.scss"

export default function({ location }) {
  return (
    <footer className={`${styles.footer} grey-background p-4`}>
      <Container>
        <Nav className="row">
          <Col xs="6" md="2">
            <h4>Moonvision</h4>
            <ul>
              <li>
                <Link location={location} to="/about">
                  <FormattedMessage id="footerAbout" />
                </Link>
              </li>
              <li>
                <Link location={location} to="/contact">
                  <FormattedMessage id="footerContact" />
                </Link>
              </li>
              <li>
                <Link location={location} to="/imprint">
                  <FormattedMessage id="footerImprint" />
                </Link>
              </li>
              <li>
                <Link location={location} to="/terms-conditions">
                  <FormattedMessage id="footerTermsConditions" />
                </Link>
              </li>
              <li>
                <Link location={location} to="/data-privacy">
                  <FormattedMessage id="footerDataPrivacy" />
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs="6" md="2">
            <h4>
              <FormattedMessage id="footerTechnology" />
            </h4>
            <ul className="pb-3">
              <li>
                <Link location={location} to="/technology">
                  <FormattedMessage id="footerOverview" />
                </Link>
              </li>
            </ul>
            <h4>
              <FormattedMessage id="footerPartnership" />
            </h4>
            <ul>
              <li>
                <Link location={location} to="/partnership">
                  <FormattedMessage id="footerOverview" />
                </Link>
              </li>
              <li>
                <Link location={location} to="/partnership/certified-partner">
                  <FormattedMessage id="footerPartnershipCertification" />
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs="6" md="2" className="py-4 py-md-1">
            <h4>
              <FormattedMessage id="footerContact" />
            </h4>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/moonvision.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UC73h633WcuUCdAmJDW-zymg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Youtube
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/moonvision/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Linkedin
                </a>
              </li>
              <li>
                <a
                  href="mailto:office@moonvision.io"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mail
                </a>
              </li>
            </ul>
          </Col>
          <Col xs="7" md="1" className="py-4 py-md-1 offset-md-3">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.forbesdach.com/artikel/top-30-start-ups-aus-dem-dach-raum.html"
              className={styles.forbesLogo}
            >
              <img
                src={forbes}
                alt="top 30 AI-Startups from the DACH region"
                className="mt-4"
              />
              <p>Top 30</p>
            </a>
            <img
              src={isoCertification}
              alt="ISO 9001 and ISO 27001 Certified Company"
              className="mt-4"
            />
          </Col>
          <Col xs="6" md="2" className="py-4 py-md-1">
            <p>
              The Moonvision GmbH
              <br />
              
              <br />
              Glockengasse 4, Top 105, 1020 Vienna (AT)
              <br />
              www.moonvision.io
              <br />
              <a href="tel:+43 676 6918980">+43 676 6918980</a>
            </p>
          </Col>
        </Nav>
      </Container>
    </footer>
  )
}
