import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "../Navbar/Navbar"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { StaticQuery, graphql } from "gatsby"
import { IntlProvider } from "react-intl"
import "intl"
import CookieConsent from "components/CookieConsent/index"

export default function({ children, header, location, i18nMessages }) {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              siteUrl
              languages {
                defaultLangKey
                langs
              }
            }
          }
        }
      `}
      render={data => {
        const url = location.pathname
        const { langs, defaultLangKey } = data.site.siteMetadata.languages
        const siteUrl = data.site.siteMetadata.siteUrl
        const langKey = getCurrentLangKey(langs, defaultLangKey, url)
        const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/")
        const langsMenu = getLangs(
          langs,
          langKey,
          getUrlForLang(homeLink, url)
        ).map(item => ({
          ...item,
          link: item.link
            .replace(`/${defaultLangKey}/`, "/")
            .replace("//", "/"),
        }))

        return (
          <IntlProvider locale={langKey} messages={i18nMessages}>
            <div id="app">
              <Helmet>
                <html lang={langKey} />
                {langsMenu.map(
                  lang =>
                    !lang.selected && (
                      <link
                        key={lang.langKey}
                        rel="alternate"
                        hreflang={lang.langKey}
                        href={siteUrl + lang.link}
                      />
                    )
                )}
              </Helmet>
              <Navbar langs={langsMenu} location={location} />
              {header && <Header {...header} />}
              <main className="white-background">{children}</main>
              <CookieConsent />
              <Footer location={location} />
            </div>
          </IntlProvider>
        )
      }}
    />
  )
}
