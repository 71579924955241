module.exports = {
  // contact
  contactUs: "contact us",
  callUs: "call us",

  // Navigation.js
  navigationProducts: "Products",
  navigationTechnology: "Technology",
  navigationUsecases: "Use Cases",
  navigationConsulting: "Consulting",
  navigationPartner: "Partner",
  navigationCareer: "Career",
  navigationSignup: "Signup free",

  // Cookies Popup
  cookieText:
    "<p>This website uses cookies to ensure you get the best experience on our website.</p><a href='https://www.cookiesandyou.com/' target='_blank' rel='noopener noreferrer'>Learn more</a>",
  cookieAccept: "Accept",

  // Contact Us
  contactTitle: "Get in touch with us now:",
  contactCall: "Call",
  contactEmail: "Email",

  // Contact Section
  contactSectionHeader:
    "You can start using with our products in no time.<br/>No process changes, no hassle - simple to use.",
  contactSectionText:
    "Learn about our products through demo installations, usecases similar to yours and talk to our team how our solutions can best help you",
  contactSectionButton: "Start exploring Computer Vision",
  goToProductsButton: "Our Products",

  // Product
  productOverview: "Product Overview",
  productDetails: "Product Details",
  productTechnical: "Technical Specification",
  productContactNext: "Send us a request",
  productContactPoints:
    "<li>Offer</li><li>Product Trial</li><li>Technical support</li>",
  productContactCTA: "Further questions regarding",
  freeconsultation: `Get a free Consultation for {product} now`,
  freeconsultationText:
    "Upload hard to automate images here to get a free consultation now. We agree to delete all images after the consultation and ensure the privacy. Our data security guidelines make sure they are untouchable by others and the confidentiality is very high.",
  freeconsultationImages:
    "Click here or drop a file to upload: Best results with 10-50 images.",
  freeconsultationImagesCount: `{images, number} {images, plural, one {image} other {images}} ready for upload:`,
  freeconsultationSubmit: "Submit for free consultation",
  freeconsultationSuccess:
    "Thank you for your request, we will contact you shortly",
  freeconsultationError: "Please provide a valid Email Address",

  // Consulting
  consultingText:
    "<h2>Images are the <strong>base</strong> for <strong>your digitization</strong>..</h2><p>Benefit from our broad experience in the field of AI/AI consulting as well as implemented projects in the areas of computer vision and deep learning. Don't make the same mistakes that other companies have already experienced, but make early use of the field-proven knowledge of our experienced experts.<p>",
  consultingBlueBoxAreasHeader: "<h2>Consulting Areas</h2>",
  consultingBlueBoxVisual: "Visual Surface Analysis",
  consultingBlueBoxQuality: "Quality Assurance",
  consultingBlueBoxAI:
    "Computer Vision AI as an extension of your existing business model",
  consultingBlueBoxModel:
    "Innovative business models based on Computer Vision AI",
  consultingBlueBoxProduct: "Product Inspection",
  consultingBlueBoxProcess: "Process Inspection",
  consultingContactUsSectionHeader: "In which areas can we support you?",
  consultingContactUsSectionButton: "Contact",
  // technology slider
  sliderBenefits: "Our benefits",
  sliderHeading1: "Data Lake",
  sliderText1: "Learn once, run everywhere",
  sliderHeading2: "APIs",
  sliderText2:
    "Support different ERP systems, automation systems and realtime messaging systems.",
  sliderHeading3: "Individual",
  sliderText3: "Custom configurations for your specific quality criteria",
  sliderHeading4: "Allrounder",
  sliderText4: "Support of most production processes",
  sliderHeading5: "Data Reports",
  sliderText5: "Image based quality assurance reports",

  // ToolBox product
  toolBoxVideo: "Video Data Processing",
  toolBoxManufacturing: "Support of all manufacturing processes",
  toolBoxAdaptation: "Adaptation to changing requirements",

  //carreer
  carreerForm: "Application Form",
  carreerFirstName: "First Name",
  carreerLastName: "Last Name",
  carreerTel: "Telephone Number",
  carreerLinkedIn: "Your personal profile, e.g. Github, LinkedIn or homepage",
  carreerCoverLetter: "Cover Letter upload",
  carreerCV: "CV and Cover Letter upload",
  carreerAllowDataTypes:
    "Maximum 3 files and 15 MB per file allowed. Allowed data formats: PDF, .doc, .docx, png, jpeg, jpg",
  carreerApplyNow: "Submit",
  carreerSuccessMessage: "Application successfully submited!",

  // footer
  footerAbout: "About",
  // footerPress: "Press",
  footerContact: "Contact",
  footerImprint: "Imprint",
  footerTermsConditions: "Terms & Conditions",
  footerDataPrivacy: "Data Privacy",
  footerTechnology: "Technology",
  footerOverview: "Overview",
  footerPartnership: "Partnership",
  footerPartnershipCertification: "Certified Partners",
}
